import { useAuthStore } from "@/stores/auth";

export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    console.log("middleware OTP redirect to:", to);
    const auth = useAuthStore();
    console.log("auth.otpExpiresAt:", auth.otpExpiresAt);
    console.log("auth.otpExpiresAt === 0:", auth.otpExpiresAt === 0);
    if (auth.otpExpiresAt === 0) {
      return navigateTo("/login", { replace: true });
    }
  }
});
